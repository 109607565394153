<template>
  <v-menu :close-on-content-click="!collapsibleSubGroups">
    <template v-slot:activator="{ on }">
      <v-btn v-on="on" text>{{ label }}</v-btn>
    </template>
    <link-list :category="category" :collapsible-sub-groups="collapsibleSubGroups" />
  </v-menu>
</template>
<script>
export default {
  components: {
    LinkList: () => import('./LinkList')
  },
  props: {
    category: {
      type: String,
      required: true
    },
    collapsibleSubGroups: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      required: true
    }
  }
}
</script>
